<template>
  <!-- <div class="flex flex-nowrap justify-between px-5 py-2 border-b border-gray-200 items-center">
        <div class="flex">
            <slot name="title"/>
            <slot name="actions"/>
        </div>
        
        <div class="flex">
            <slot name="actionButtons"/>
        </div>
    </div> -->

  <div
    class="flex flex-nowrap justify-between px-5 h-12 border-b border-gray-200 items-center"
  >
    <div class="flex items-center">
      <div class="hidden sm:block">
        <slot name="title" />
      </div>
      <slot name="actions" />
    </div>

    <div class="">
      <div class="flex">
        <slot name="actionButtons" />
      </div>
    </div>
  </div>

  <!-- <v-toolbar dense color="white" class="border-b border-gray-500 w-full z-40">
    <v-toolbar-title v-if="title" class="mr-4">{{ title }}</v-toolbar-title>
    <v-toolbar-items v-if="$slots['actions']">
      <slot name="actions"></slot>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items v-if="realCrud.length">
      <v-btn
        text
        color="primary"
        small
        v-for="(cr, c) in realCrud"
        :key="c"
        @click="clickButton(cr)"
        ><v-icon>{{ crudIcon(cr).icon }}</v-icon
        >{{ crudIcon(cr).label }}</v-btn
      >
    </v-toolbar-items>
  </v-toolbar> -->
</template>

<script>
import cruds from '@/plugins/crud'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    crud: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    realCrud() {
      return this.crud.filter(cr => cruds.some(c => c.label == cr))
    }
  },
  mounted() {
    console.log('RENDERIZZATA')
  },
  methods: {
    crudIcon(c) {
      return cruds.filter(cr => cr.label == c)[0]
    },
    clickButton(c) {
      console.log('Emit Click ', c)
      this.$emit(c)
    }
  }
}
</script>

<style></style>
