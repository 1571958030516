export default [
  {
    icon: 'add',
    label: 'add'
  },
  {
    icon: 'delete',
    label: 'delete'
  },
  {
    icon: 'mdi-format-list-bulleted',
    label: 'list'
  },
  {
    icon: 'edit',
    label: 'edit'
  },
  {
    icon: 'cached',
    label: 'reset'
  }
]
