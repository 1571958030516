<template>
  <div class="left-0 right-0 top-12 bottom-0 overscroll-y-auto overflow-y-auto">
    <tool-bar
      title="Tags"
      :crud="['add']"
      @add="$router.push({ path: 'create' })"
    ></tool-bar>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ToolBar from '@/_components/ToolBar.vue'
export default {
  components: {
    ToolBar
  }
}
</script>

<style></style>
